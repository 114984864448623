
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import DataPage from "./pages/data";
import React, {useEffect, useState} from "react";
import USER from '../src/auth/user';
import axios from "axios";
import {BACK_END_URL, DOMAIN_URL} from "./auth/api";

function App() {

  // AUTH
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const [email, setLogin] = useState("");
    const [password, setPassword] = useState("");
    // const [errorMessage, setErrorMessage] = React.useState("");
    // const [successAuth, setSuccessAuth] = React.useState(false);

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const fieldEmail = (event) => {
        setLogin(event.target.value);
    }

    const fieldPassword = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log('login: ' + email);
        // console.log('password: ' + password);

        if(email === '' || password === ''){
            setError('Input Email or Password');
            setLoading(false);
        }else {

            let data = {email, password}

            function authenticatedCallback() {

                return <DataPage />;
            }

            setLoading(true);

            axios.post(BACK_END_URL + 'auth/login', JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }).then(response => {

                setLoading(false);
                console.log('Logged successfully!');
                setError(null);

                USER.authenticated(response.data.access_token, response.data, authenticatedCallback);

            }).catch(error => {
                setError(error.message);
                setLoading(false);
                setPassword('');
                setLogin('')
            });
        }
    }



    if(USER.isSignIn()){
        return <DataPage />;
    }


  return (
    <div classNameName="Admin">
        <div className="relative sm:flex sm:justify-center sm:items-center min-h-screen bg-dots-darker bg-center bg-gray-100 dark:bg-dots-lighter dark:bg-gray-900 selection:bg-red-500 selection:text-white">
            <div className="bg-light py-3 py-md-5">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-4">
                            <div className="bg-white p-4 p-md-5 rounded shadow-sm">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center mb-5">
                                            <a href="#!">
                                                <img src="https://t3.ftcdn.net/jpg/03/22/41/64/360_F_322416406_0RkSxyaaG1Q0nv2QLslLFLEVRge7NE0E.jpg" alt="Logo" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {

                                    error !== null &&
                                        <p className={'text-danger'}>
                                            {error}
                                        </p>
                                }

                                <form id="login_form" onSubmit={handleSubmit}>
                                    <div className="row gy-3 gy-md-4 overflow-hidden">
                                        <div className="col-12">
                                            <label for="email" className="form-label">Email <span className="text-danger">*</span></label>

                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                    </svg>
                                                </span>
                                                <input type="email" className="form-control" name="email" id="email"
                                                       value={email}
                                                       onChange={fieldEmail}
                                                       required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label for="password" className="form-label">Пароль <span className="text-danger">*</span></label>
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                                        <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                                                        <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                    </svg>
                                                </span>
                                                <input type="password" className="form-control" name="password" id="password"
                                                       value={password}
                                                       onChange={fieldPassword}
                                                       required />
                                            </div>

                                        </div>
                                        <div className="col-12">
                                            <div className="d-grid">
                                                {
                                                    loading ? <div>Авторизація...</div> :

                                                        <input type="submit" disabled={loading} value="Вхід"
                                                               className="btn btn-primary btn-lg"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-12">
                                        <hr className="mt-5 mb-4 border-secondary-subtle" />
                                            <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center">
                                                <a href={DOMAIN_URL + "page?view=download&pin=190289"} target={'_blank'} className="link-secondary text-decoration-none">Download App</a>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
}

export default App;
