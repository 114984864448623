class User {

    constructor() {
        this.init();
    }

    init(){
        this.token = localStorage.getItem('token');
        this.id = localStorage.getItem('id');
        this.email = localStorage.getItem('email');
        this.isAuth = localStorage.getItem('isAuth');
    }

    authenticated(token, data, callback){

        console.log(data.status);

        localStorage.setItem('token', token);
        localStorage.setItem('id', data.id);
        localStorage.setItem('email', data.email);
        localStorage.setItem('isAuth', data.status);

        this.init()

        callback();
    }
    //
    // updateInformation(data){
    //     localStorage.setItem('id', data.id);
    //     localStorage.setItem('name', data.name);
    //     localStorage.setItem('email', data.email);
    //
    //     this.init()
    // }
    //
    isSignIn(){
        return Boolean(this.isAuth) === true;
    }

    logout(callback){
        localStorage.clear();
        sessionStorage.clear();

        this.init();

        callback();
    }
}

export default new User()
