import React, {useEffect, useState} from "react";
import USER from '../auth/user';
import axios from "axios";
import {BACK_END_URL} from "../auth/api";

function DataPage() {

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // load data + search

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData()
    }, [])

    // search
    const [query, setQuery] = useState('');

    const fetchData = () => {

        setLoading(true);
        //
        axios.get(BACK_END_URL + 'data', {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + USER.token
            }
        }).then(response => {

            setLoading(false);
            console.log('Log Out successfully!');
            setData(response.data.data)
            setError(null);

        }).catch(error => {
            setError(error.message);
            setLoading(false);
        });
    }

    // remove record
    const handleRemoveRecord = (id) => {

        setLoading(true);
        //
        axios.delete(BACK_END_URL + 'record?user=' + id, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + USER.token
            }
        }).then(response => {

            setLoading(false);
            setError(null);

            const _data = data.filter((a) => a.id !== id);
            setData(_data)

            alert('Звпис #' + id + ' видалеон!')

        }).catch(error => {
            setError(error.message);
            setLoading(false);
        });
    }

    // logout
    const handleLogOut = (event) => {

        event.preventDefault();

        setLoading(true);

        axios.post(BACK_END_URL + 'logout', [], {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + USER.token
            }
        }).then(response => {

            function logoutCallback() {

                window.location.reload();
            }

            setLoading(false);
            console.log('Log Out successfully!');
            setError(null);

            USER.logout(logoutCallback);

        }).catch(error => {
            setError(error.message);
            setLoading(false);
        });
    }

    return <div
        className="relative sm:flex sm:justify-center sm:items-center min-h-screen bg-dots-darker bg-center bg-gray-100 dark:bg-dots-lighter dark:bg-gray-900 selection:bg-red-500 selection:text-white">

        <div className="bg-light py-3 py-md-5">
            <div className="container vh-100">

                <div className="nav-menu">
                    <div className="d-flex justify-content-between align-items-center">

                        <h1>
                            Дані
                        </h1>


                        <input id="searchQuery" type="text" placeholder="Пошук..." className="form-control w-50"
                               value={query}
                               onChange={(e) => setQuery(e.target.value)}
                        />

                        <div className={'d-flex'}>
                            <small className="text-secondary p-2">
                                {USER.email ?? '-EMAIL-'}
                            </small>
                            {

                                error !== null &&
                                <p className={'text-danger'}>
                                    {error}
                                </p>
                            }
                            {
                                loading ? <div>Вихід...</div> :
                                    <button type="submit" disabled={loading} className="btn btn-outline-danger"
                                            onClick={handleLogOut}>Вихід</button>
                            }
                        </div>

                    </div>

                    <div className="mt-5 d-flex flex-wrap">

                        {
                            data === null && <p className="text-secondary">
                                Даних немає!
                            </p>
                        }

                        {data?.length > 0 && data.filter((data) =>
                            data.user.name
                                .toLowerCase()
                                .includes(query.toLowerCase()))
                            .map((record, i) => <div className="col-md-3 p-2">
                                {/*onClick={() => detailPage(i+1)}>*/}
                                <div className="rounded-2 shadow d-flex align-items-center">
                                    <div className="phone col-md-4 text-center border-end p-1">
                                        <img
                                            src="https://t3.ftcdn.net/jpg/03/22/41/64/360_F_322416406_0RkSxyaaG1Q0nv2QLslLFLEVRge7NE0E.jpg"
                                            alt="Logo" className="img-fluid"/>
                                        <small className="text-secondary mt-2" style={{fontSize: '10px'}}>
                                            {record.device.name} {record.device.os}
                                        </small>
                                        <br/>
                                        <small
                                            className={record.user.status === 'ACTIVE' ? 'text-success' : 'text-danger'}
                                            style={{fontSize: '8px'}}>
                                            {record.user.status}
                                        </small>
                                    </div>
                                    <div className="info p-3 col-md-8">
                                        <div className="d-flex justify-content-between">
                                            <h5>
                                                {record.user.name}
                                            </h5>

                                            <div className="remove-record">
                                                <button type="button" className="btn-close" onClick={() => handleRemoveRecord(record.id)}
                                                        aria-label="Remove" />
                                            </div>
                                        </div>

                                        <small
                                            className="text-secondary">{record.user.email ?? ''}<br/>{record.user.phone ?? ''}
                                        </small>

                                        {
                                            (record.locations.length > 0 || record.files.length > 0) &&
                                            <div className="d-flex mt-2 border-top">

                                                <button type="button" className="btn btn-light mt-1"
                                                        data-bs-toggle="modal" data-bs-target={'#detailModal_' + i}>
                                                    Вкладення
                                                </button>

                                                <div className="modal fade" id={'detailModal_' + i} tabIndex="-1"
                                                     aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> {record.user.name}</h5>
                                                                <button type="button" className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body d-flex" style={{height: '400px'}}>
                                                                <div className="col-6 p-1">
                                                                    <small> Локація
                                                                        ({record.locations.length})
                                                                        {/*Остання: {record.locations[record.locations.length-1].latitude},{record.locations[record.locations.length-1].longitude}*/}
                                                                    </small><br/>

                                                                    {/*<iframe*/}
                                                                    {/*    width="100%" height="400"*/}
                                                                    {/*    frameBorder="0"*/}
                                                                    {/*    scrolling="no"*/}
                                                                    {/*    marginHeight="0"*/}
                                                                    {/*    marginWidth="0"*/}
                                                                    {/*    // src={*/}
                                                                    {/*    //     `https://maps.google.com/maps?q=${record.locations[record.locations.length-1].latitude},${record.locations[record.locations.length-1].longitude}&hl=es;z=14&amp;output=embed;`*/}
                                                                    {/*    // }*/}
                                                                    {/*    src={*/}
                                                                    {/*        'https://maps.google.com/maps?q=50.2667942,28.686032&hl=es;z=14&amp;output=embed'*/}
                                                                    {/*    }*/}
                                                                    {/*/>*/}
                                                                    {/*<br/>*/}

                                                                    <div className="d-flex flex-column">
                                                                        {
                                                                            record.locations.map((item, key) => <div className={'p-1'}>
                                                                                <a
                                                                                    href={"https://www.google.com/maps/place/" + item.latitude + "," + item.longitude}
                                                                                    target="_blank"
                                                                                    className={'text-decoration-none text-secondary'}
                                                                                ><small  style={{fontSize: '12px'}}>#{key+1} {item.data} | {item.latitude + ", " + item.longitude}
                                                                                    {/*.split(' ')[0]*/}
                                                                                </small></a>
                                                                            </div>)
                                                                        }
                                                                    </div>

                                                                </div>
                                                                <div className="col-6 p-1">
                                                                    <small>Галерея смартфону</small>
                                                                    <div className="d-flex flex-wrap">
                                                                        {
                                                                            record.files.map((item, key) => <div className={'p-1'} style={{width: "50%", height: "70px", objectFit: 'contain'}}>
                                                                                <img
                                                                                    src={item.url}
                                                                                    alt={item.file.name}
                                                                                    className="img-fluid" />
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer d-flex justify-content-between">
                                                                <small
                                                                    className="text-secondary">{record.user.email ?? ''} | {record.user.phone ?? ''}
                                                                </small>
                                                                <button type="button" className="btn btn-secondary"
                                                                        data-bs-dismiss="modal">Закрить
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>)}

                    </div>

                </div>
            </div>

        </div>

    </div>;
}

export default DataPage;
